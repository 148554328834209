<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-12 mb-0">
                <div class="card">
                    <div class="card-body">
                        <div class="float-sm-left">
                            <router-link
                                    to="/riders"
                                    class="btn btn-info"
                            ><i class="mdi mdi-arrow-left-circle-outline mr-1"></i> Back
                            </router-link >
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 mx-0" v-if="riderDetail">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="avatar-xl mx-auto mt-1">
                            <div class="avatar-title bg-light rounded-circle">
                                <i class="mdi mdi-account h1 m-0 text-body"></i>
                            </div>
                        </div>

                        <h4 class="mt-3 mb-0">{{riderDetail.identity === null ? '' : riderDetail.identity.surname}} {{riderDetail.identity.othernames}}</h4>
                        <p class="text-muted">{{riderDetail.identity.email}}</p>

                        <button
                                type="button"
                                class="btn btn-danger btn-xs waves-effect mb-2 waves-light"
                                v-if="riderDetail.identity"
                        >
                            {{riderDetail.identity.accountType}}
                        </button>

                        <div class="text-left mt-3">
                            <h4 class="font-13 text-uppercase">About Me :</h4>
                            <p class="text-muted font-13 mb-3">
                                {{riderDetail.identity.address}}
                            </p>

                            <div class="table-responsive">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                    <tr>
                                        <th scope="row">Gender :</th>
                                        <td class="text-muted">{{ riderDetail.identity.gender}} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mobile :</th>
                                        <td class="text-muted">{{ riderDetail.identity.phoneNumber}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Date of birth :</th>
                                        <td class="text-muted"> {{ riderDetail.identity.dob | moment("MMMM Do YYYY")}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Place of Work :</th>
                                        <td class="text-muted">{{ riderDetail.identity.placeOfWork}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- end card-box -->
                </div>
            </div>
            <!-- end col-->

            <div class="col-lg-8 col-xl-8 mx-0">

                <div class="card mb-2">
                    <div class="card-body">
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title>
                                    <i class=" mr-1"></i>Current Order
                                </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                            <tr>
                                                <th>Order Number</th>
                                                <th>Payment Status</th>
                                                <th>Total Price</th>
                                                <th>Payment method</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td v-if="riderDetail.currentOrder !== null"> <router-link class="text-primary mr-2" style="cursor: pointer; border-bottom: 2px dotted #029F96;" :to="'/pharmacy/order/'+riderDetail.currentOrder.id">{{riderDetail.currentOrder === null ? "----" : riderDetail.currentOrder.orderNumber}}</router-link> </td>
                                                <td v-if="riderDetail.currentOrder !== null">
                                                 <span v-if="riderDetail.currentOrder !== null" class="p-1 badge" :class="riderDetail.currentOrder.hasPaid === false ? 'badge-danger' :'badge-primary'"
                                                 >{{riderDetail.currentOrder === null ? "----" :  riderDetail.currentOrder.hasPaid === false ? 'NOT PAID' : 'PAID'}}</span></td>
                                                <td><span v-if="riderDetail.currentOrder !== null" class="badge badge-outline-success">GHS {{riderDetail.currentOrder === null ? "----" : riderDetail.currentOrder.totalPrice}}</span></td>
                                                <td><span v-if="riderDetail.currentOrder !== null" class="badge badge-success p-1"> {{riderDetail.currentOrder === null ? "----" : riderDetail.currentOrder.paymentMethod}}</span></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                            <b-tab>
                                <template v-slot:title>
                                    <i class="mdi mdi-settings-outline mr-1"></i>List Of Orders
                                </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                            <tr>
                                                <th>Order Number</th>
                                                <th>Order Status</th>
                                                <th>Total Price</th>
                                                <th>Payment Status</th>
                                                <th>Order by</th>

                                            </tr>
                                            </thead>
                                            <tbody v-if="listOfOrders">
                                            <tr v-for="order in listOfOrders" :key="order.id">
                                                <td> <router-link class="text-primary mr-2" style="cursor: pointer; border-bottom: 2px dotted #029F96;" :to="'/pharmacy/order/'+order.id">{{order.orderNumber === null ? "----" : order.orderNumber}}</router-link> </td>
                                                <td>

                                                    <span
                                                            class="p-1 badge badge-soft-success"
                                                            :class="{
                                                              'badge-soft-danger':
                                                                order.status === 'PENDING',
                                                              'badge-soft-primary':
                                                                order.status === 'ONGOING',
                                                                'badge-soft-info':
                                                                order.status === 'APPROVED_CONFIRMATION',
                                                            }">
                                                         {{order.status}}
                                                    </span>
                                                </td>

                                                <td><span class="badge badge-outline-success">GHS {{order.totalPrice}}</span></td>
                                                <td><span class="badge p-1"
                                                          :class="order.hasPaid === false ? 'badge-danger' :'badge-primary'"> {{order.hasPaid === false ? 'NOT PAID' : 'PAID'}}</span></td>
                                                <td>
                                                    <router-link class="text-primary mr-2" style="cursor: pointer; border-bottom: 2px dotted #029F96;" :to="'/patients/'+order.patient.id">
                                                        {{order.patient.identity == null ? "" : order.patient.identity.surname }} {{order.patient.identity == null ? "" : order.patient.identity.othernames}}</router-link></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-box-->
                </div>

            </div>
            <!-- end col -->
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    export default {
        name: "ViewRiderDetails",
        components : {
            Layout,
            PageHeader
        },
        data(){
            return {
                riderDetail : {},
                listOfOrders : [],
                orders : [],
                title: "Rider details",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "Rider details",
                        active: true,
                    },
                ],
                showLoader :false,
            }
        },
        methods : {
            getRiderDetailsByRiderId(){
                BaseUrl.AxiosBearer.get(`/admin/riders/profile/${this.$route.params.Id}`).then((resp) => {

                    if(resp.data.status) {
                        this.riderDetail = resp.data.payload
                        console.log("rider : ", this.riderDetail)
                    }
                }).catch((error) => {
                    console.log("error : ", error.message)
                })
            },
            getOtherOrderForTheRidersByRiderId() {
                BaseUrl.AxiosBearer.get(`/admin/riders/profile/${this.$route.params.Id}/orders?page=1&pageSize=10000`).then((resp) => {

                    if(resp.data.status) {
                        this.orders = resp.data.payload
                        console.log("orders  : ", this.orders)
                    }
                }).catch((error) => {
                    console.log("error : ", error.message)
                })
            },
            getListOfOrders() {
                BaseUrl.AxiosBearer.get(`/admin/riders/profile/${this.$route.params.Id}/orders?page=1&pageSize=10000`).then((resp) => {
                    if(resp.data.status) {
                        this.listOfOrders = resp.data.payload
                    }
                }).catch((error) => {
                    console.log("error : ", error.message)
                })
            }
        },
        created() {
            this.getRiderDetailsByRiderId()
            this.getOtherOrderForTheRidersByRiderId()
            this.getListOfOrders()
        }
    }
</script>

<style scoped>

</style>